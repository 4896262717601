<template>
  <TemplateDashboard>
    <main>
      <div class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        <!-- Welcome banner -->
        <WelcomeBanner :user_name="user_name" />

        <!-- Dashboard actions -->
        <div class="sm:flex sm:justify-between sm:items-center mb-8">
          <!-- Right: Actions -->
          <div
            class="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2 hidden"
          >
            <!-- Filter button -->
            <FilterButton align="right" />
            <!-- Datepicker built with flatpickr -->
            <Datepicker align="right" />
            <!-- Add view button -->
            <button class="btn bg-emerald-500 hover:bg-emerald-600 text-white">
              <svg
                class="w-4 h-4 fill-current opacity-50 shrink-0"
                viewBox="0 0 16 16"
              >
                <path
                  d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z"
                />
              </svg>
              <span class="hidden xs:block ml-2">Tambah View</span>
            </button>
          </div>
        </div>

        <!-- Cards -->
        <div class="grid grid-cols-12 gap-6">
          <DashboardCard01 v-if="false" />
          <!-- chart count if -->
          <DashboardCardCountIF
            class="cursor-pointer hover:bg-blue-500/20 duration-150 transition-all"
            @click="router.push('/data/instansi')"
            :data="dashboard.if"
            :load_data="load_data"
            v-if="['super_admin', 'admin_provinsi'].indexOf(user_role) !== -1"
          />
          <!-- chart count faskes -->
          <DashboardCardCountFaskes
            class="cursor-pointer hover:bg-blue-500/20 duration-150 transition-all"
            @click="router.push('/data/instansi')"
            :data="dashboard.faskes"
            :load_data="load_data"
            v-if="['super_admin', 'admin_provinsi'].indexOf(user_role) !== -1"
          />
          <!-- chart count product -->
          <DashboardCardCountProduct
            class="cursor-pointer hover:bg-blue-500/20 duration-150 transition-all"
            @click="router.push('/data/barang?group=6565e73d39a0d479eaa99296')"
            :data="dashboard.product"
            :load_data="load_data"
          />
          <!-- chart count alkes -->
          <DashboardCardCountAlkes
            class="cursor-pointer hover:bg-blue-500/20 duration-150 transition-all"
            @click="router.push('/data/barang?group=662713e437182c507b3fde68')"
            :data="dashboard.alkes"
            :load_data="load_data"
          />
          <!-- chart count productWithStock -->
          <DashboardCardCountProductStock
            class="cursor-pointer hover:bg-blue-500/20 duration-150 transition-all"
            @click="
              router.push('/stok?hideEmptyStock=662713e437182c507b3fde68')
            "
            :data="dashboard.productWithStock"
            :load_data="load_data"
          />
          <!-- chart count alkesWithStock -->
          <DashboardCardCountAlkesStock
            class="cursor-pointer hover:bg-blue-500/20 duration-150 transition-all"
            @click="
              router.push(
                '/stok?hideEmptyStock=662713e437182c507b3fde68&group=662713e437182c507b3fde68'
              )
            "
            :data="dashboard.alkesWithStock"
            :load_data="load_data"
          />
          <!-- chart count expiredProduct -->
          <DashboardCardCountEDProduct
            class="cursor-pointer hover:bg-blue-500/20 duration-150 transition-all"
            @click="router.push('/monitoring/expired?expired=true')"
            :data="dashboard.expiredProduct"
            :load_data="load_data"
          />
          <!-- chart count nearExpiryProduct -->
          <DashboardCardCountNearEDProduct
            class="cursor-pointer hover:bg-blue-500/20 duration-150 transition-all"
            @click="router.push('/monitoring/expired?nearExpired=true')"
            :data="dashboard.nearExpiryProduct"
            :load_data="load_data"
          />
          <!-- chart count unProcessedPermintaan -->
          <DashboardCardCountUnProcess
            class="cursor-pointer hover:bg-blue-500/20 duration-150 transition-all"
            @click="router.push('/permintaan/masuk?isSubmit=true')"
            :data="dashboard.unProcessedPermintaan"
            :load_data="load_data"
          />
        </div>
      </div>
    </main>
  </TemplateDashboard>
</template>

<script>
import { inject, onMounted, ref } from "vue";
import TemplateDashboard from "../templates/Dashboard.vue";
import WelcomeBanner from "../partials/dashboard/WelcomeBanner.vue";
import FilterButton from "../components/DropdownFilter.vue";
import DashboardCard01 from "../partials/dashboard/DashboardCard01.vue";
import DashboardCardCountIF from "../partials/dashboard/DashboardCardCountIF.vue";
import DashboardCardCountFaskes from "../partials/dashboard/DashboardCardCountFaskes.vue";
import DashboardCardCountProduct from "../partials/dashboard/DashboardCardCountProduct.vue";
import DashboardCardCountAlkes from "../partials/dashboard/DashboardCardCountAlkes.vue";
import DashboardCardCountProductStock from "../partials/dashboard/DashboardCardCountProductStock.vue";
import DashboardCardCountAlkesStock from "../partials/dashboard/DashboardCardCountAlkesStock.vue";
import DashboardCardCountEDProduct from "../partials/dashboard/DashboardCardCountEDProduct.vue";
import DashboardCardCountNearEDProduct from "../partials/dashboard/DashboardCardCountNearEDProduct.vue";
import DashboardCardCountUnProcess from "../partials/dashboard/DashboardCardCountUnProcess.vue";
import Datepicker from "../components/Datepicker.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  name: "Dashboard",
  components: {
    TemplateDashboard,
    WelcomeBanner,
    FilterButton,
    Datepicker,
    DashboardCard01,
    DashboardCardCountIF,
    DashboardCardCountFaskes,
    DashboardCardCountProduct,
    DashboardCardCountAlkes,
    DashboardCardCountProductStock,
    DashboardCardCountAlkesStock,
    DashboardCardCountEDProduct,
    DashboardCardCountNearEDProduct,
    DashboardCardCountUnProcess,
  },
  setup() {
    const axios = inject("axios");
    const router = useRouter();
    const store = useStore(); // Get the Vuex store instance
    const { getUserData } = store.getters;

    const load_data = ref(false);

    const user = getUserData;
    let user_name = ref(user.name);
    let user_role = ref(user.role);
    let dashboard = ref([]);

    const fetchData = async () => {
      let endpoint = "/report/dashboard";

      load_data.value = true;
      await axios
        .get(endpoint)
        .then(async ({ data }) => {
          load_data.value = false;
          dashboard.value = data;
        })
        .catch(() => {
          load_data.value = false;
        });
    };

    onMounted(() => {
      fetchData();
    });

    return {
      user_name,
      load_data,
      dashboard,
      user_role,
      router,
    };
  },
};
</script>
