<template>
  <template
    v-for="(product, productIndex) in products"
    :key="`${index}_${productIndex}`"
  >
    <tr
      :class="{
        'cursor-pointer hover:opacity-70 transition-all duration-300':
          $route.path.includes('/retur/instansi/add'),
        'bg-emerald-50 bg-opacity-70':
          index % 2 == 0 && $route.path.includes('/permintaan/keluar'),
        ...classItem,
      }"
    >
      <td
        class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r"
        v-if="productIndex == 0"
        :rowspan="request.products.length"
      >
        <div class="text-left" :class="{ 'opacity-0': productIndex != 0 }">
          {{ request.referenceNo }}
        </div>
      </td>
      <td
        class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r"
        v-if="productIndex == 0"
        :rowspan="request.products.length"
      >
        <div
          class="text-wrap text-center overflow-visible"
          :class="{ 'opacity-0': productIndex != 0 }"
        >
          {{ request.sourceInstance.name }}
        </div>
      </td>
      <td
        class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r"
        v-if="productIndex == 0"
        :rowspan="request.products.length"
      >
        <div
          class="text-center text-wrap"
          :class="{ 'opacity-0': productIndex != 0 }"
        >
          {{ request.targetInstance.name }}
        </div>
      </td>
      <!-- catatan -->
      <td
        class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r"
        v-if="productIndex == 0"
        :rowspan="request.products.length"
      >
        <div
          class="text-center text-wrap"
          :class="{ 'opacity-0': productIndex != 0 }"
        >
          {{ request.remark }}
        </div>
      </td>
      <!-- status -->
      <td
        class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r h-full"
        v-if="productIndex == 0"
        :rowspan="request.products.length"
      >
        <div
          class="text-center px-2.5 py-1 rounded-full w-full flex justify-center"
          :class="{
            'bg-emerald-100 text-emerald-600': request.status == 'COMPLETED',
            'bg-yellow-100 text-yellow-600': request.status == 'PROCESSING',
            'bg-red-100 text-red-600': request.status == 'CANCELLED',
            'bg-indigo-100 text-indigo-600': request.status == 'SUBMITTED',
            'opacity-0': productIndex != 0,
          }"
        >
          {{ transform_status(request.status) }}
        </div>
      </td>
      <!-- nama barang yg di berikan -->
      <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r">
        <div class="text-center text-wrap">{{ product.name }}</div>
      </td>
      <!-- Jumlah diminta -->
      <td class="px-2 py-3 whitespace-nowrap border-r">
        <div class="text-center text-wrap">
          {{ product?.quantity }}
        </div>
      </td>
      <!-- Nama Obat yg diberikan -->
      <td class="whitespace-nowrap border-r">
        <!-- inner child element -->
        <div v-if="request.processedProducts?.length > 0" class="h-full">
          <div
            class="py-4 border-b w-full px-3 h-full"
            v-for="(product_selected, index) in product.processedProducts"
            :class="{
              'border-none': index == product.processedProducts.length - 1,
            }"
            :key="index"
          >
            <div class="w-full py-2 flex justify-center items-center">
              {{ product_selected.name }}
            </div>
          </div>
        </div>
        <div v-else>
          <div
            class="py-4 border-b w-full px-3"
            v-for="(product_selected, index) in product.selected"
            :key="index"
          >
            <div class="w-full py-2 flex justify-center items-center">
              {{ product_selected.name }}
            </div>
          </div>
          <div class="mx-3 my-3 h-[42px]"></div>
        </div>
        <!-- element helper -->
      </td>
      <!-- jumlah Diberikan -->
      <td class="whitespace-nowrap border-r">
        <!-- inner child element -->
        <div v-if="request.processedProducts?.length > 0" class="h-full">
          <div
            class="py-4 border-b w-full px-3 h-full"
            v-for="(product_selected, index) in product.processedProducts"
            :class="{
              'border-none': index == product.processedProducts.length - 1,
            }"
            :key="index"
          >
            <div class="w-full py-2 flex justify-center items-center">
              {{ product_selected.quantity }}
            </div>
          </div>
        </div>
        <div v-else>
          <div
            class="py-4 border-b w-full px-3"
            v-for="(product_selected, index) in product.selected"
            :key="index"
          >
            <div class="w-full py-2 flex justify-center items-center">
              {{ product_selected.quantity }}
            </div>
          </div>
          <div class="mx-3 my-3 h-[42px]"></div>
        </div>
        <!-- element helper -->
      </td>
      <!-- sumber dana -->
      <td class="whitespace-nowrap border-r">
        <!-- inner child element -->
        <div v-if="request.processedProducts?.length > 0">
          <div
            class="py-4 border-b w-full px-3"
            v-for="(product_selected, index) in product.processedProducts"
            :class="{
              'border-none': index == product.processedProducts.length - 1,
            }"
            :key="index"
          >
            <div class="w-full py-2 flex justify-center items-center">
              {{ product_selected.fundsName }}
            </div>
          </div>
        </div>
        <div v-else>
          <div
            class="py-4 border-b w-full px-3"
            v-for="(product_selected, index) in product.selected"
            :key="index"
          >
            <div class="w-full py-2 flex justify-center items-center">
              {{ product_selected.fundsName }}
            </div>
          </div>
          <div class="mx-3 my-3 h-[42px]"></div>
        </div>
        <!-- element helper -->
      </td>
      <!-- expireed -->
      <td class="whitespace-nowrap border-r">
        <!-- inner child element -->
        <div v-if="request.processedProducts?.length > 0">
          <div
            class="py-4 border-b w-full px-3"
            v-for="(product_selected, index) in product.processedProducts"
            :class="{
              'border-none': index == product.processedProducts.length - 1,
            }"
            :key="index"
          >
            <div class="w-full py-2 flex justify-center items-center">
              {{ product_selected.expiry }}
            </div>
          </div>
        </div>
        <div v-else>
          <div
            class="py-4 border-b w-full px-3"
            v-for="(product_selected, index) in product.selected"
            :key="index"
          >
            <div class="w-full py-2 flex justify-center items-center">
              {{ product_selected.expiry }}
            </div>
          </div>
          <div class="mx-3 my-3 h-[42px]"></div>
        </div>
        <!-- element helper -->
      </td>
      <!-- No Batch -->
      <td class="whitespace-nowrap border-r">
        <!-- inner child element -->
        <div v-if="request.processedProducts?.length > 0">
          <div
            class="py-4 border-b w-full px-3"
            v-for="(product_selected, index) in product.processedProducts"
            :class="{
              'border-none': index == product.processedProducts.length - 1,
            }"
            :key="index"
          >
            <div class="w-full py-2 flex justify-center items-center">
              {{ product_selected.batchNo }}
            </div>
          </div>
        </div>
        <div v-else>
          <div
            class="py-4 border-b w-full px-3"
            v-for="(product_selected, index) in product.selected"
            :key="index"
          >
            <div class="w-full py-2 flex justify-center items-center">
              {{ product_selected.batchNo }}
            </div>
          </div>
          <div class="mx-3 my-3 h-[42px]"></div>
        </div>
        <!-- element helper -->
      </td>
      <!-- tanggal permintaan -->
      <td
        class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r"
        v-if="productIndex == 0"
        :rowspan="request.products.length"
      >
        <div class="text-center" :class="{ 'opacity-0': productIndex != 0 }">
          {{ formatTanggal(request.date) }}
        </div>
      </td>
      <!-- aksi -->
      <td
        class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px relative"
        v-if="checkActCondition(productIndex)"
        :rowspan="request.products.length"
      >
        <!-- edit -->
        <router-link
          v-if="request.status == 'SUBMITTED' || loading.cancel"
          :to="{ name: 'PermintaanEdit', params: { id: request.id } }"
          class="w-full flex justify-center py-1 rounded-sm hover:opacity-70 transition-opacity duration-300 items-center bg-slate-700 text-white p-1 px-6 gap-1"
        >
          <span> Edit </span>
        </router-link>
        <!-- unduh nota permintaan barang / nota permintaan -->
        <a
          :class="{
            ' bg-emerald-600 opacity-50 pointer-events-none cursor-not-allowed':
              !['SUBMITTED', 'PROCESSING', 'COMPLETED'].includes(
                request.status
              ),
          }"
          :href="`https://api-ifk-kalsel.bimajaya.co.id/v1/permintaan/${request.id}/notaPermintaanBarang`"
          class="w-full mt-1 flex justify-center py-1 rounded-sm hover:opacity-70 transition-opacity duration-300 items-center bg-emerald-600 text-white p-1 px-2 gap-1"
          target="_blank"
          rel="noopener noreferrer"
        >
          Unduh Nota Permintaan
        </a>
        <!-- unduh surat berita acara -->
        <a
          :class="{
            ' bg-emerald-600 opacity-50 pointer-events-none cursor-not-allowed':
              !['PROCESSING', 'COMPLETED'].includes(request.status),
          }"
          :href="`https://api-ifk-kalsel.bimajaya.co.id/v1/permintaan/${request.id}/berita-acara`"
          class="w-full mt-1 flex justify-center py-1 rounded-sm hover:opacity-70 transition-opacity duration-300 items-center bg-emerald-600 text-white p-1 px-2 gap-1"
          target="_blank"
          rel="noopener noreferrer"
        >
          Unduh Surat Berita Acara
        </a>
        <!-- unduh nota -->
        <a
          :class="{
            ' bg-emerald-600 opacity-50 pointer-events-none cursor-not-allowed':
              !['PROCESSING', 'COMPLETED'].includes(request.status),
          }"
          :href="`https://api-ifk-kalsel.bimajaya.co.id/v1/permintaan/${request.id}/sppb`"
          class="w-full mt-1 flex justify-center py-1 rounded-sm hover:opacity-70 transition-opacity duration-300 items-center bg-emerald-600 text-white p-1 px-2 gap-1"
          target="_blank"
          rel="noopener noreferrer"
          v-if="
            !(
              request.targetInstance.type == 'faskes' &&
              request.sourceInstance.type == 'faskes'
            )
          "
        >
          Unduh SPPB
        </a>
        <!-- verify -->
        <button
          type="button"
          @click="verify(request.id)"
          class="w-full mt-1 flex justify-center py-1 rounded-sm hover:opacity-50 transition-opacity duration-300 items-center bg-emerald-600 text-white p-1 px-2 gap-1"
          :class="{
            'cursor-not-allowed bg-emerald-600 opacity-50':
              (request.status != 'PROCESSING' &&
                user_instansi == request.sourceInstance.id) ||
              loading.verify,
          }"
          :disabled="
            (request.status != 'PROCESSING' &&
              user_instansi == request.sourceInstance.id) ||
            loading.verify
          "
        >
          <svg
            v-if="loading.verify"
            class="animate-spin mr-3 h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <span> Verifikasi </span>
        </button>
        <!-- batal -->
        <button
          type="button"
          @click="cancel(request.id)"
          class="w-full mt-1 flex justify-center py-1 rounded-sm hover:opacity-50 transition-opacity duration-300 items-center bg-red-400 text-white p-1 px-6 gap-1"
          :class="{
            'cursor-not-allowed bg-red-400 opacity-50':
              request.status != 'SUBMITTED' || loading.cancel,
          }"
          :disabled="request.status != 'SUBMITTED'"
        >
          <svg
            v-if="loading.cancel"
            class="animate-spin mr-3 h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <span> Batalkan </span>
        </button>
        <!-- hapus -->
        <button
          @click="removeItem(request.id)"
          class="w-full mt-1 flex justify-center py-1 rounded-sm hover:opacity-70 transition-opacity duration-300 items-center bg-red-400 text-white p-1 px-6 gap-1"
          :class="{
            'cursor-not-allowed bg-red-400 opacity-50':
              request.status != 'CANCELLED' || loading.delete,
          }"
          :disabled="request.status != 'CANCELLED'"
        >
          <span> Hapus </span>
        </button>
      </td>
    </tr>
  </template>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { inject } from "vue";
import Swal from "sweetalert2";
import SwallToast from "../../../helpers/swall-toast";
import { transform_status } from "../../../utils/Utils";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "PermintaansTableItem",
  props: ["request", "value", "selected", "index", "classItem"],
  emits: ["data-selcted", "updateData", "get-data"],
  components: {},
  setup(props, context) {
    const axios = inject("axios");
    const route = useRoute();
    const store = useStore();
    let products = ref([]);
    const { getUserData } = store.getters;
    let user_instansi = ref(getUserData.instance.id);
    const checked = computed(() => {
      let selected = props.selected;
      let permintaan = props.request.id;
      const existingIndex = selected.findIndex(
        (item) => item.permintaan === permintaan
      );

      if (existingIndex !== -1) {
        return true;
      } else {
        return false;
      }
    });
    let modalOpen = ref(false);
    let loading = ref({
      proses: false,
      unduh: false,
      verify: false,
      cancel: false,
      delete: false,
    });

    function check() {
      let updatedSelected = [...props.selected];
      if (this.checked) {
        updatedSelected.splice(updatedSelected.indexOf(props.value), 1);
      } else {
        updatedSelected.push(props.value);
      }
      context.emit("update:selected", updatedSelected);
    }

    const formatTanggal = (inputTanggal) => {
      const tanggalObjek = new Date(inputTanggal);
      const tahun = tanggalObjek.getFullYear();
      const bulan = ("0" + (tanggalObjek.getMonth() + 1)).slice(-2);
      const tanggal = ("0" + tanggalObjek.getDate()).slice(-2);

      return `${tahun}-${bulan}-${tanggal}`;
    };

    const removeItem = async (id) => {
      Swal.fire({
        title: "Alert",
        icon: "warning",
        text: "Apakah Anda ingin menghapus permintaan ini?",
        showDenyButton: true,
        confirmButtonText: "Yes",
        denyButtonText: `No`,
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            await axios.delete(`/permintaan/${id}`).then((response) => {
              SwallToast("Berhasil hapus permintaan", "success");
              context.emit("updateData");
            });
          }
        })
        .catch(() => {
          SwallToast("Gagal hapus permintaan", "error");
        });
    };

    const cancel = async (id) => {
      loading.value.cancel = true;
      await axios
        .patch(`/permintaan/${id}/cancel`)
        .then(async () => {
          loading.value.cancel = false;
          context.emit("get-data");
          SwallToast("Berhasil melakukan verifikasi permintaan", "success");
        })
        .catch(() => {
          loading.value.cancel = false;
          SwallToast("Gagal melakukan verifikasi permintaan", "error");
        });
    };

    const verify = async (id) => {
      loading.value.verify = true;
      await axios
        .patch(`/permintaan/${id}/verify`)
        .then(async () => {
          loading.value.verify = false;
          context.emit("get-data");
          SwallToast("Berhasil melakukan prosess permintaan", "success");
        })
        .catch(() => {
          loading.value.verify = false;
          SwallToast("Gagal melakukan prosess permintaan", "error");
          // load_data.value = false;
        });
    };

    const formatRupiah = (angka) => {
      const numberFormat = new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      });

      return numberFormat.format(angka);
    };

    const checkActCondition = (productIndex) => {
      if (!route.path.includes("/retur/instansi/add")) {
        if (productIndex == 0) {
          return true;
        }
      }
      return false;
    };

    const checkboxCondition = (productIndex) => {
      if (!route.path.includes("/retur/instansi/add")) {
        return false;
      } else {
        if (productIndex != 0) {
          return false;
        }
        return true;
      }
    };

    const selectData = ({ inventoryId, productId, quantity }, permintaanId) => {
      if (route.path.includes("/retur/instansi/add")) {
        context.emit(
          "data-selcted",
          { inventoryId, productId, quantity },
          permintaanId
        );
      }
    };

    const checkDataHasSelected = (permintaan) => {
      let selected = props.selected;
      const existingIndex = selected.findIndex(
        (item) => item.permintaan === permintaan
      );

      if (existingIndex !== -1) {
        return true;
      } else {
        return false;
      }
    };

    onMounted(() => {
      products.value = props.request.products.map((item) => {
        let product = { ...item };
        let processedProducts = [];
        if (props.request.processedProducts.length > 0) {
          processedProducts = props.request.processedProducts.filter(
            (item) => item.requestedId === product.id
          );
        }
        return { ...product, selected: [], processedProducts };
      });
    });

    return {
      check,
      checked,
      removeItem,
      formatTanggal,
      modalOpen,
      formatRupiah,
      transform_status,
      cancel,
      verify,
      loading,
      checkActCondition,
      checkboxCondition,
      selectData,
      checkDataHasSelected,
      user_instansi,
      products,
    };
  },
};
</script>
