<template>
  <div class="bg-white shadow-lg rounded-sm border border-slate-200 relative">
    <header class="px-5 py-4 md:flex justify-between items-center gap-20">
      <h2 class="font-semibold text-slate-800 mb-5 md:mb-0">
        Semua Data
        <span class="text-slate-400 font-medium ml-1">{{
          list_rpop.totalResults
        }}</span>
      </h2>
      <div class="md:flex items-end gap-2 w-full md:flex-1">
        <!-- filter -->
        <div class="flex max-xs:flex-col gap-2 xl:items-end mb-2 md:mb-0">
          <div
            class="grid gap-3 grid-cols-1 xl:grid-cols-2 relative mb-5 md:mb-0"
          >
            <!-- instance -->
            <div
              class="col-span-1 max-xs:col-span-1"
              v-if="checkRole(['admin_kabupaten'])"
            >
              <label class="text-sm"> Filter Instansi </label>
              <SelectSearch
                ref="selectInstanceRef"
                :placeholder="'Pilih Instansi'"
                :config="select_config_instance"
                :state_form="form_update_state"
                @change-item="
                  (item) => {
                    table_config.filter.instance = item;
                  }
                "
              />
            </div>
            <div class="box-content">
              <label for="startDate" class="text-xs">Acuan</label>
              <select
                class="w-full border-slate-300 rounded col-span-1 p-[6px] pl-4 cursor-pointer"
                v-model="table_config.filter.acuan"
              >
                <option disabled value="">Pilih Tahun</option>
                <option
                  v-for="year in years"
                  :key="year"
                  :value="year"
                  :selected="year === table_config.filter.acuan"
                >
                  {{ year }}
                </option>
              </select>
              <input
                id="acuan"
                type="date"
                v-model="table_config.filter.acuan"
                class="hidden w-full border-slate-300 rounded col-span-1 p-[6px]"
              />
            </div>
          </div>
          <button
            type="button"
            @click="fetchInventory()"
            class="btn border border-slate-700 max-xs:w-full px-5 hover:opacity-60"
            :class="{ 'opacity-40 cursor-not-allowed': loading_expor }"
            :disabled="loading_expor"
          >
            <Loading v-if="loading_expor" />
            <span>Filter</span>
          </button>
          <!-- reset filter -->
          <div class="max-xs:w-full">
            <button
              @click="resetFilter"
              type="button"
              class="btn bg-slate-700 hover:bg-slate-800 text-white w-full md:w-fit h-fit py-2 justify-self-end"
            >
              <span class="ml-2">Reset</span>
            </button>
          </div>
        </div>

        <button
          type="button"
          @click="doExport()"
          class="btn bg-emerald-500 text-white flex items-center justify-center hover:opacity-60 w-full xl:w-[8rem]"
          :class="loading_export && 'opacity-60 cursor-not-allowed'"
          :disabled="loading_export"
        >
          <Loading v-if="loading_export" />
          <span v-else>Expor RPOP</span>
        </button>
      </div>
    </header>
    <div>
      <!-- Table -->
      <div class="overflow-x-auto">
        <table class="table-auto w-full">
          <!-- Table header -->
          <thead
            class="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200"
          >
            <tr>
              <th
                class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[40%]"
              >
                <div class="font-semibold">Barang</div>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div class="font-semibold">Kemasan</div>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div class="font-semibold">Satuan</div>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div class="font-semibold">Kosong(BL)</div>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div class="font-semibold">Sisa Stock</div>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div class="font-semibold">Jumlah</div>
              </th>
              <th
                class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[12rem]"
              >
                <div class="font-semibold">Rata-Rata</div>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div class="font-semibold">Terkoreksi</div>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div class="font-semibold">Optimum</div>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div class="font-semibold">Proyeksi</div>
              </th>
            </tr>
          </thead>
          <!-- Table body -->
          <tbody class="text-sm divide-y divide-slate-200">
            <tr v-if="load_data">
              <td colspan="11" class="col-span-11">
                <div
                  class="flex items-center justify-center gap-5 flex-col p-6"
                >
                  <svg
                    class="animate-spin mr-3 h-7 w-7"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      class="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      class="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  <span>Sedang memuat data ...</span>
                </div>
              </td>
            </tr>

            <Rpop
              v-for="(stok, index) in list_rpop.results"
              :class="{ hidden: load_data }"
              :key="stok.id"
              :stok="stok"
              :index="index"
              v-model:selected="selected"
              :value="stok.id"
              @updateData="fetchData()"
            />
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import Rpop from "./TableItem.vue";
import { inject } from "vue";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import Loading from "../../components/Loading.vue";
import SelectSearch from "../../components/SelectSearch.vue";
import { onClickOutside } from "@vueuse/core";
import { checkRole } from "../../helpers/authoriztion.service";
import { yearsCustomFormData } from "../../utils/Utils";

export default {
  name: "Rpop Table",
  components: {
    Rpop,
    Loading,
    SelectSearch,
  },
  props: ["selectedItems"],
  setup(props, { emit }) {
    const axios = inject("axios");
    const store = useStore();
    const { getUserData } = store.getters;
    const user = ref(getUserData);
    const selectAll = ref(false);
    const selected = ref([]);
    let loading_export = ref(false);
    let load_data = ref(false);
    const years = ref([]);
    let table_config = ref({
      filter: {
        acuan: new Date().getFullYear(),
        instance: null,
      },
      limit: 10,
      page: 1,
      totalPages: 0,
      totalResults: 0,
    });

    let list_rpop = ref([]);

    let selectInstanceRef = ref(null);
    let select_config_instance = ref({
      head: [
        {
          head: {
            classList: "w-[24rem]",
          },
          text: {
            name: "Nama Kabupaten",
            classList: "text-left",
          },
        },
        {
          head: {
            classList: "w-[1rem]",
          },
          text: {
            name: "Aksi",
            classList: "text-center",
          },
        },
      ],
      input: {
        classList: "py-[5.8px] w-full",
      },
      data: null,
      data_temp: null, //data temp for search query
      selected: {
        text: null,
        classList: "py-1",
      },
      position: "bottom",
    });

    onClickOutside(
      selectInstanceRef,
      () => (selectInstanceRef.value.options.state = false)
    );

    const fetchDataFilter = async () => {
      await axios
        .get("/instance?limit=100000")
        .then(({ data }) => {
          // Filter data berdasarkan role pengguna
          const filteredResults = data.results.filter((result) => {
            // Cek jika rolenya admin_kabupaten
            if (user.user_role == "admin_kabupaten") {
              // Cek jika tipe instance adalah if_kabupaten atau faskes
              return result.type === "faskes";
            }
            // Jika bukan rolenya admin_kabupaten, biarkan instance tersebut tidak difilter
            return true;
          });

          // Memperbarui data dengan hasil filter
          data.results = filteredResults;

          select_config_instance.value.data_temp = data.results; // var temp digunakan untuk data temp instance ketika query search
          select_config_instance.value.data = data;
        })
        .catch(() => {});
    };

    const fetchInventory = async (
      exportAkrual = false,
      limit = table_config.value.limit,
      page = table_config.value.page
    ) => {
      load_data.value = true;

      let endpoint = `/inventory/procurement-plan?limit=${limit}&page=${page}`;

      let filter = table_config.value.filter;
      if (filter.instance) {
        let instance = filter.instance.toString();
        endpoint += `&instance=${instance}`;
      } else {
        endpoint += `&instance=${user.value.instance.id}`;
      }

      if (filter.acuan) {
        let acuan = filter.acuan;
        endpoint += `&acuan=${acuan}`;
      }

      await axios
        .get(endpoint)
        .then(async ({ data }) => {
          load_data.value = false;
          if (!exportAkrual) {
            load_data.value = false;
            // Penanda untuk data obat yang mirip sebelumnya
            // data.results.forEach((item, index) => {
            //   const isDuplicate = data.results
            //     .slice(0, index)
            //     .some(
            //       (prevItem) =>
            //         prevItem.productName === item.productName &&
            //         prevItem.fundsName === item.fundsName &&
            //         prevItem.expiry === item.expiry
            //     );
            //   item.isDuplicate = isDuplicate;
            // });

            list_rpop.value = data;
            table_config.value.limit = data.limit;
            table_config.value.page = data.page;
            table_config.value.totalPages = data.totalPages;
            table_config.value.totalResults = data.totalResults;
          } else {
            Swal.fire({
              title: "<strong>Info</strong>",
              text: "Proses expor sedang berlangsung, silahkan cek berkala di halaman riwayat export!",
              icon: "info",
              focusConfirm: true,
              confirmButtonText: `
              <a href="/riwayat/expor">Lihat Riwayat</a>
              `,
              confirmButtonAriaLabel: "Lihat Riwayat",
            });
            loading_export.value = false;
          }
        })
        .catch(() => {
          load_data.value = false;
        });
    };

    const doExport = async () => {
      loading_export.value = true;

      let endpoint = `/inventory/export-procurement-plan`;
      let filter = table_config.value.filter;

      if (filter.instance) {
        let instance = filter.instance.toString();
        endpoint += `?instance=${instance}`;
      } else {
        endpoint += `?instance=${user.value.instance.id}`;
      }

      if (filter.acuan) {
        let acuan = filter.acuan;
        endpoint += `&acuan=${acuan}`;
      }

      await axios
        .get(endpoint)
        .then(async () => {
          Swal.fire({
            title: "<strong>Info</strong>",
            text: "Proses expor berhasil dilakukan, silahkan download di halaman riwayat export!",
            icon: "info",
            focusConfirm: true,
            confirmButtonText: `
              <a href="/riwayat/expor">Lihat Riwayat</a>
              `,
            confirmButtonAriaLabel: "Lihat Riwayat",
          });
          loading_export.value = false;
        })
        .catch(() => {
          loading_export.value = false;
        });
    };

    const next_fetch = () => {
      list_rpop.value = [];
      table_config.value.page++;
      fetchInventory();
    };

    const prev_fetch = () => {
      list_rpop.value = [];
      table_config.value.page--;
      fetchInventory();
    };

    const handleSearchQuery = (data) => {
      table_config.value.filter.product = data.query;
      table_config.value.page = 1;
      fetchInventory();
    };

    const resetFilter = async () => {
      table_config.value.filter.instance = null;
      table_config.value.filter.acuan = 2024;

      select_config_instance.value.selected.text = "";
      await fetchInventory();
    };

    onMounted(async () => {
      await fetchInventory();
      fetchDataFilter();

      years.value = yearsCustomFormData();
    });

    return {
      selectAll,
      selected,
      list_rpop,
      fetchInventory,
      table_config,
      load_data,
      loading_export,
      next_fetch,
      prev_fetch,
      handleSearchQuery,
      doExport,
      select_config_instance,
      checkRole,
      years,
      selectInstanceRef,
      resetFilter,
    };
  },
};
</script>
