<template>
  <div
    class="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white shadow-lg rounded-sm border border-slate-200"
  >
    <div class="px-5 py-5">
      <header class="flex justify-between items-start mb-2">
        <!-- Icon -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-dashboard"
          width="32"
          height="32"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#94A3B8"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M12 13m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
          <path d="M13.45 11.55l2.05 -2.05" />
          <path d="M6.4 20a9 9 0 1 1 11.2 0z" />
        </svg>
        <div class="underline underline-offset-4 text-slate-600 text-sm">
          Detail
        </div>
      </header>
      <h2 class="text-lg font-semibold text-slate-800 mb-2">Jumlah FASKES</h2>
      <div class="text-xs font-semibold text-slate-400 uppercase mb-1 hidden">
        Sales
      </div>
      <div class="flex items-start">
        <div class="text-3xl font-bold text-slate-800 mr-2">{{ data }}</div>
        <div
          class="text-sm font-semibold text-white px-1.5 bg-emerald-500 rounded-full hidden"
        >
          +49%
        </div>
      </div>
    </div>
    <!-- Chart built with Chart.js 3 -->
    <div v-if="false" class="grow">
      <!-- Change the height attribute to adjust the chart height -->
      <LineChart :data="chartData" width="389" height="128" />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import LineChart from "../../charts/LineChart01.vue";
import EditMenu from "../../components/DropdownEditMenu.vue";

// Import utilities
import { tailwindConfig, hexToRGB } from "../../utils/Utils";

export default {
  name: "DashboardCountFaskes",
  components: {
    LineChart,
    EditMenu,
  },
  props: ["data", "load_data"],
  setup() {
    const chartData = ref({
      labels: [
        "12-01-2020",
        "01-01-2021",
        "02-01-2021",
        "03-01-2021",
        "04-01-2021",
        "05-01-2021",
        "06-01-2021",
        "07-01-2021",
        "08-01-2021",
        "09-01-2021",
        "10-01-2021",
        "11-01-2021",
        "12-01-2021",
        "01-01-2022",
        "02-01-2022",
        "03-01-2022",
        "04-01-2022",
        "05-01-2022",
        "06-01-2022",
        "07-01-2022",
        "08-01-2022",
        "09-01-2022",
        "10-01-2022",
        "11-01-2022",
        "12-01-2022",
        "01-01-2023",
      ],
      datasets: [
        // Indigo line
        {
          data: [
            732, 610, 610, 504, 504, 504, 349, 349, 504, 342, 504, 610, 391,
            192, 154, 273, 191, 191, 126, 263, 349, 252, 423, 622, 470, 532,
          ],
          fill: true,
          backgroundColor: `rgba(${hexToRGB(
            tailwindConfig().theme.colors.blue[500]
          )}, 0.08)`,
          borderColor: tailwindConfig().theme.colors.indigo[500],
          borderWidth: 2,
          tension: 0,
          pointRadius: 0,
          pointHoverRadius: 3,
          pointBackgroundColor: tailwindConfig().theme.colors.indigo[500],
          clip: 20,
        },
        // Gray line
        {
          data: [
            532, 532, 532, 404, 404, 314, 314, 314, 314, 314, 234, 314, 234,
            234, 314, 314, 314, 388, 314, 202, 202, 202, 202, 314, 720, 642,
          ],
          borderColor: tailwindConfig().theme.colors.slate[300],
          borderWidth: 2,
          tension: 0,
          pointRadius: 0,
          pointHoverRadius: 3,
          pointBackgroundColor: tailwindConfig().theme.colors.slate[300],
          clip: 20,
        },
      ],
    });

    return {
      chartData,
    };
  },
};
</script>
