<template>
  <TemplateDashboard>
    <main>
      <div class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        <!-- Page header -->
        <div class="mb-8">
          <!-- Left: Title -->
          <div class="mb-4 sm:mb-0 max-sm:mb-16">
            <h1
              class="text-2xl md:text-3xl text-slate-800 font-bold flex items-center gap-2"
            >
              <span>Kartu Stok</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-packages stroke-slate-700"
                width="30"
                height="30"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M7 16.5l-5 -3l5 -3l5 3v5.5l-5 3z" />
                <path d="M2 13.5v5.5l5 3" />
                <path d="M7 16.545l5 -3.03" />
                <path d="M17 16.5l-5 -3l5 -3l5 3v5.5l-5 3z" />
                <path d="M12 19l5 3" />
                <path d="M17 16.5l5 -3" />
                <path d="M12 13.5v-5.5l-5 -3l5 -3l5 3v5.5" />
                <path d="M7 5.03v5.455" />
                <path d="M12 8l5 -3" />
              </svg>
            </h1>
          </div>

          <!-- filter -->
          <div class="w-full grid grid-cols-1 mt-10">
            <!-- button toggle filter -->
            <div
              @click="
                () => {
                  toggleFilter.state = !toggleFilter.state;
                  toggleFilter.text =
                    toggleFilter.text == 'Sembunyikan Filter'
                      ? 'Tampilkan Filter'
                      : 'Sembunyikan Filter';
                }
              "
              class="col-span-1 relative my-7 cursor-pointer hover:opacity-70"
            >
              <hr class="border-0.5 border-black border-opacity-20" />
              <div class="w-full flex justify-center absolute -top-3 left-0">
                <span class="bg-slate-100 px-6 text-sm font-semibold">{{
                  toggleFilter.text
                }}</span>
              </div>
            </div>
            <!-- list filter -->
            <div
              v-if="toggleFilter.state"
              class="w-full flex gap-2 max-xs:flex-col"
            >
              <!-- list filter -->
              <div class="w-full grid grid-cols-1">
                <!-- filter 1 -->
                <div
                  class="col-span-1 grid grid-cols-2 gap-2 max-xs:grid-cols-1"
                >
                  <!-- filter sumber dana -->
                  <div class="col-span-1">
                    <label class="text-sm">Filter Sumber Dana</label>
                    <SelectSearch
                      ref="selectFundRef"
                      :placeholder="'Pilih Sumber Dana'"
                      :config="select_config_fund"
                      :state_form="form_update_state"
                      @change-item="
                        (item) => {
                          const itemFound =
                            select_config_fund.data.results.find(
                              (_item) => item == _item.id
                            );
                          table_config.filter.fund = itemFound.name;
                        }
                      "
                    />
                  </div>
                  <!-- filter noBatch -->
                  <div class="col-span-1">
                    <label class="text-sm">Filter No Batch</label>
                    <input
                      class="form-input focus:border-slate-300 w-full"
                      type="text"
                      v-model="table_config.filter.noBatch"
                    />
                  </div>
                </div>
                <!-- filter 2 -->
                <div
                  class="col-span-1 grid grid-cols-4 gap-2 max-xs:grid-cols-1"
                >
                  <!-- mutasi range date -->
                  <div
                    class="col-span-4 grid grid-cols-2 gap-2 max-xs:grid-cols-1"
                  >
                    <!-- start date -->
                    <div class="col-span-1">
                      <label for="filter_supplier" class="text-sm">
                        Tanggal Awal Mutasi:
                      </label>
                      <div class="relative">
                        <input
                          type="date"
                          v-model="table_config.filter.minMD"
                          class="border border-slate-300 rounded h-[2.5rem] w-full"
                        />
                        <span
                          v-if="form_update_state"
                          class="absolute text-sm top-[8px] left-3"
                          >Sedang memuat data ...</span
                        >
                      </div>
                    </div>
                    <!-- end date -->
                    <div class="col-span-1">
                      <label for="filter_supplier" class="text-sm">
                        Tanggal Akhir Mutasi:
                      </label>
                      <!-- supplier -->
                      <div class="relative">
                        <input
                          type="date"
                          v-model="table_config.filter.maxMD"
                          class="border border-slate-300 rounded h-[2.5rem] w-full"
                        />
                        <span
                          v-if="form_update_state"
                          class="absolute text-sm top-[8px] left-3"
                          >Sedang memuat data ...</span
                        >
                      </div>
                    </div>
                  </div>

                  <!-- expired range date -->
                  <div class="col-span-2 grid grid-cols-2 gap-2" v-if="false">
                    <!-- start date -->
                    <div class="col-span-1">
                      <label for="filter_supplier" class="text-sm">
                        Tanggal Awal Expired:
                      </label>
                      <!-- supplier -->
                      <div class="relative">
                        <input
                          type="date"
                          class="border border-slate-300 rounded h-[2.5rem] w-full"
                        />
                        <span
                          v-if="form_update_state"
                          class="absolute text-sm top-[8px] left-3"
                          >Sedang memuat data ...</span
                        >
                      </div>
                    </div>
                    <!-- end date -->
                    <div class="col-span-1">
                      <label for="filter_supplier" class="text-sm">
                        Tanggal Akhir Expired:
                      </label>
                      <!-- supplier -->
                      <div class="relative">
                        <input
                          type="date"
                          class="border border-slate-300 rounded h-[2.5rem] w-full"
                        />
                        <span
                          v-if="form_update_state"
                          class="absolute text-sm top-[8px] left-3"
                          >Sedang memuat data ...</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- filter actions -->
              <div
                class="w-[15rem] max-xs:w-full flex justify-end gap-2 max-xs:flex-col mt-6"
              >
                <!-- filter -->
                <div class="max-xs:w-full w-[45%]">
                  <button
                    @click="filter"
                    type="button"
                    class="btn bg-emerald-500 hover:bg-emerald-600 text-white w-full h-fit py-2 justify-self-end"
                  >
                    <span class="ml-2">Filter</span>
                  </button>
                </div>
                <!-- reset filter -->
                <div class="max-xs:w-full w-[45%]">
                  <button
                    @click="resetFilter"
                    type="button"
                    class="btn bg-slate-700 hover:bg-slate-800 text-white w-full h-fit py-2 justify-self-end"
                  >
                    <span class="ml-2">Reset</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Table -->
        <StokTable
          ref="childComponentRef"
          @change-selection="updateSelectedItems($event)"
        />
      </div>
    </main>
  </TemplateDashboard>
</template>

<script>
import { inject, onMounted, ref, watch } from "vue";
import TemplateDashboard from "../../templates/Dashboard.vue";
import DeleteButton from "../../partials/actions/DeleteButton.vue";
import DateSelect from "../../components/DateSelect.vue";
import FilterButton from "../../components/DropdownFilter.vue";
import StokTable from "../../partials/stok/kartu_stok/Table.vue";
import SearchForm2 from "../../components/SearchForm2.vue";
import SelectSearch from "../../components/SelectSearch.vue";
import { onClickOutside } from "@vueuse/core";
import { checkRole } from "../../helpers/authoriztion.service";

export default {
  name: "StokPage",
  components: {
    TemplateDashboard,
    DeleteButton,
    DateSelect,
    FilterButton,
    StokTable,
    SearchForm2,
    SelectSearch,
  },
  setup() {
    const axios = inject("axios");
    const childComponentRef = ref(null);
    let data = ref(null);

    let toggleFilter = ref({
      state: true,
      text: "Sembunyikan Filter",
    });

    let table_config = ref({
      filter: {
        minMD: null, // MD : Mutation Date
        maxMD: null,
        // minED: null, // ED : Expireed Date
        // maxED: null,
        fund: null,
        noBatch: null,
      },
      limit: 10,
      page: 1,
      totalPages: 0,
      totalResults: 0,
    });

    let selectInstanceRef = ref(null);
    let select_config_instance = ref({
      head: [
        {
          head: {
            classList: "w-[24rem]",
          },
          text: {
            name: "Nama Kabupaten",
            classList: "text-left",
          },
        },
        {
          head: {
            classList: "w-[1rem]",
          },
          text: {
            name: "Aksi",
            classList: "text-center",
          },
        },
      ],
      input: {
        classList: "py-[5.8px]",
      },
      data: null,
      data_temp: null, //data temp for search query
      selected: {
        text: null,
        classList: "py-1",
      },
      position: "bottom",
    });

    let selectFundRef = ref(null);
    let select_config_fund = ref({
      head: [
        {
          head: {
            classList: "w-[24rem]",
          },
          text: {
            name: "Nama Kabupaten",
            classList: "text-left",
          },
        },
        {
          head: {
            classList: "w-[1rem]",
          },
          text: {
            name: "Aksi",
            classList: "text-center",
          },
        },
      ],
      input: {
        classList: "py-[5.8px]",
      },
      data: null,
      data_temp: null, //data temp for search query
      selected: {
        text: null,
        classList: "py-1",
      },
      position: "bottom",
    });

    let selectKategoriRef = ref(null);
    let select_config_kategori = ref({
      head: [
        {
          head: {
            classList: "w-[24rem]",
          },
          text: {
            name: "Nama Kabupaten",
            classList: "text-left",
          },
        },
        {
          head: {
            classList: "w-[1rem]",
          },
          text: {
            name: "Aksi",
            classList: "text-center",
          },
        },
      ],
      input: {
        classList: "py-[5.8px]",
      },
      data: null,
      data_temp: null, //data temp for search query
      selected: {
        text: null,
        classList: "py-1",
      },
      position: "bottom",
    });

    let selectSatuanRef = ref(null);
    let select_config_satuan = ref({
      head: [
        {
          head: {
            classList: "w-[24rem]",
          },
          text: {
            name: "Nama Satuan",
            classList: "text-left",
          },
        },
        {
          head: {
            classList: "w-[1rem]",
          },
          text: {
            name: "Aksi",
            classList: "text-center",
          },
        },
      ],
      input: {
        classList: "py-[5.8px]",
      },
      data: null,
      data_temp: null, //data temp for search query
      selected: {
        text: null,
        classList: "py-1",
      },
      position: "bottom",
    });

    let selectGolonganRef = ref(null);
    let select_config_golongan = ref({
      head: [
        {
          head: {
            classList: "w-[24rem]",
          },
          text: {
            name: "Nama Kabupaten",
            classList: "text-left",
          },
        },
        {
          head: {
            classList: "w-[1rem]",
          },
          text: {
            name: "Aksi",
            classList: "text-center",
          },
        },
      ],
      input: {
        classList: "py-[5.8px]",
      },
      data: null,
      data_temp: null, //data temp for search query
      selected: {
        text: null,
        classList: "py-1",
      },
      position: "bottom",
    });

    onClickOutside(
      selectInstanceRef,
      () => (selectInstanceRef.value.options.state = false)
    );

    onClickOutside(
      selectFundRef,
      () => (selectFundRef.value.options.state = false)
    );

    onClickOutside(
      selectKategoriRef,
      () => (selectKategoriRef.value.options.state = false)
    );

    onClickOutside(
      selectSatuanRef,
      () => (selectSatuanRef.value.options.state = false)
    );

    onClickOutside(
      selectGolonganRef,
      () => (selectGolonganRef.value.options.state = false)
    );

    const fetchDataFilter = async () => {
      await axios
        .get("/funds?limit=100000")
        .then(({ data }) => {
          select_config_fund.value.data_temp = data.results; // var temp digunakan untuk data temp instance ketika query search
          select_config_fund.value.data = data;
        })
        .catch(() => {});
      await axios
        .get("/instance?limit=100000")
        .then(({ data }) => {
          select_config_instance.value.data_temp = data.results; // var temp digunakan untuk data temp instance ketika query search
          select_config_instance.value.data = data;
        })
        .catch(() => {});
      await axios
        .get("/category?limit=100000")
        .then(({ data }) => {
          select_config_kategori.value.data_temp = data.results;
          select_config_kategori.value.data = data;
        })
        .catch(() => {});
      await axios
        .get("/itemType?limit=100000")
        .then(({ data }) => {
          select_config_satuan.value.data_temp = data.results;
          select_config_satuan.value.data = data;
        })
        .catch(() => {});
      await axios
        .get("/itemGroup?limit=100000")
        .then(({ data }) => {
          select_config_golongan.value.data_temp = data.results;
          select_config_golongan.value.data = data;
        })
        .catch(() => {});
    };

    const filter = () => {
      childComponentRef.value.table_config.page = 1;
      childComponentRef.value.list_stok = [];
      childComponentRef.value.fetchInventory();
    };

    const resetFilter = () => {
      table_config.value.filter.noBatch = null;
      table_config.value.filter.fund = null;
      // table_config.value.filter.minED = null;
      // table_config.value.filter.maxED = null;
      table_config.value.filter.minMD = null;
      table_config.value.filter.maxMD = null;

      select_config_fund.value.selected.text = "";
      childComponentRef.value.fetchInventory();
    };

    onMounted(async () => {
      data.value = childComponentRef.value;
      table_config.value = await childComponentRef.value.table_config;
      fetchDataFilter();
    });
    return {
      childComponentRef,
      data,
      selectInstanceRef,
      select_config_instance,
      selectFundRef,
      select_config_fund,
      selectKategoriRef,
      select_config_kategori,
      selectSatuanRef,
      select_config_satuan,
      selectGolonganRef,
      select_config_golongan,
      toggleFilter,
      table_config,
      onClickOutside,
      checkRole,
      filter,
      resetFilter,
    };
  },
};
</script>
