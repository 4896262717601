<template>
  <TemplateDashboard>
    <main class="bg-white">
      <div class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        <!-- Page header -->
        <div class="mb-8 flex items-center justify-between">
          <router-link
            :to="{ name: 'MyPermintaan' }"
            class="flex gap-2 items-center hover:opacity-70 transition-all duration-300"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-chevron-left"
              width="32"
              height="32"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#000000"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M15 6l-6 6l6 6" />
            </svg>
            <h1 class="text-xl md:text-xl text-slate-800 font-bold">Kembali</h1>
          </router-link>
          <h1
            class="text-2xl md:text-3xl text-slate-800 font-bold flex items-center gap-3"
          >
            <span>Tambah Permintaan</span>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-building-skyscraper"
              width="30"
              height="30"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#000000"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M3 21l18 0" />
              <path d="M5 21v-14l8 -4v18" />
              <path d="M19 21v-10l-6 -4" />
              <path d="M9 9l0 .01" />
              <path d="M9 12l0 .01" />
              <path d="M9 15l0 .01" />
              <path d="M9 18l0 .01" />
            </svg>
          </h1>
        </div>

        <div class="border-t border-slate-200">
          <!-- Components -->
          <div class="space-y-8 mt-8">
            <!-- Input Types -->
            <form @submit.prevent="submit">
              <div class="grid gap-4 md:grid-cols-4 w-full">
                <!-- table form & instance -->
                <div class="grid gap-4 grid-cols-4 col-span-4">
                  <!-- form search barang -->
                  <div
                    class="col-span-2 relative max-sm:col-span-4 max-sm:order-last"
                  >
                    <form
                      @submit.prevent="formSearch"
                      class="border border-slate-200 rounded px-1"
                    >
                      <div class="relative">
                        <label for="search-barang" class="sr-only"
                          >Search</label
                        >
                        <input
                          id="search-barang"
                          class="w-full border-0 focus:ring-transparent placeholder-slate-400 appearance-none py-2 pl-10 pr-4"
                          :class="{
                            'cursor-not-allowed': data.targetInstance == '',
                          }"
                          type="search"
                          placeholder="Cari Nama Barang..."
                          v-model="searchQueryBarang"
                          :disabled="data.targetInstance == ''"
                          @keyup="handleChange"
                        />
                        <button
                          class="absolute inset-0 right-auto group"
                          type="submit"
                          :disabled="data.targetInstance == ''"
                          aria-label="Search"
                        >
                          <svg
                            class="w-4 h-4 shrink-0 fill-current text-slate-400 group-hover:text-slate-500 ml-4 mr-2"
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z"
                            />
                            <path
                              d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z"
                            />
                          </svg>
                        </button>
                        <div
                          class="absolute bg-white pl-1 top-0 right-0 h-full flex justify-center items-center"
                          v-if="loading_search_form"
                        >
                          <Loading />
                        </div>
                      </div>
                    </form>
                  </div>

                  <!-- instance -->
                  <div class="col-span-2 max-sm:col-span-4 max-sm:order-first">
                    <!-- Start -->
                    <div>
                      <SelectSearch
                        ref="selectInstanceRef"
                        :placeholder="'Pilih Instansi'"
                        :config="select_config_instance"
                        :disabled="instanceDisableCheck"
                        :state_form="form_update_state"
                        @change-item="
                          (item) => {
                            data.targetInstance = item;
                          }
                        "
                      />
                    </div>
                    <!-- End -->
                  </div>
                </div>

                <!-- table left -->
                <div
                  class="overflow-x-auto col-span-2 max-sm:col-span-4"
                  :class="{ 'cursor-not-allowed': data.targetInstance == '' }"
                >
                  <h2 class="mb-2">Daftar Barang Tujuan</h2>
                  <div class="overflow-y-auto h-[20rem] border">
                    <form @submit.prevent="">
                      <table class="table-auto w-full relative">
                        <!-- Table header -->
                        <thead
                          class="text-xs sticky font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200"
                        >
                          <tr>
                            <th
                              class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap min-w-[25rem]"
                            >
                              <div class="text-left">Nama barang</div>
                            </th>
                            <th class="w-[2rem]">
                              <div class="text-center">Stok</div>
                            </th>
                            <th class="w-[2rem]">
                              <div class="text-center">Jumlah</div>
                            </th>
                            <th class="w-[2rem]">
                              <div class="text-center">Satuan</div>
                            </th>
                            <th class="w-[2rem]">
                              <div class="text-center">Aksi</div>
                            </th>
                          </tr>
                        </thead>
                        <!-- Table body -->
                        <tbody class="text-sm divide-y divide-slate-200">
                          <tr
                            v-for="(product, key) in list_products"
                            :key="key"
                            :class="{
                              'bg-emerald-100 bg-opacity-10': key % 2 == 0,
                            }"
                          >
                            <td class="first:pl-5">
                              <span>{{ product.name }}</span>
                              <span
                                class="text-emerald-500 font-semibold ml-1"
                                >{{ product.packaging }}</span
                              >
                            </td>
                            <td class="first:pl-5 text-red-500 text-center">
                              {{ product.stock }}
                            </td>
                            <td class="">
                              <div class="w-full flex justify-center my-[3px]">
                                <input
                                  type="number"
                                  placeholder="Qty"
                                  :max="product.stock"
                                  min="0"
                                  @keydown.enter="
                                    add_product(product, { ...product })
                                  "
                                  v-model="product.quantity"
                                  class="w-14 h-full rounded border px-1.5 py-1 text-center"
                                />
                              </div>
                            </td>
                            <td class="first:pl-5 text-red-500 text-center">
                              {{ product.type }}
                            </td>
                            <td class="">
                              <div class="w-full flex justify-center my-[3px]">
                                <button
                                  type="button"
                                  @click="add_product(product, { ...product })"
                                  class="flex items-center p-1 border border-slate-700 rounded hover:bg-slate-700 hover:text-white"
                                >
                                  Tambah
                                </button>
                              </div>
                            </td>
                          </tr>
                          <tr ref="thelastItem">
                            <td
                              colspan="4"
                              class="w-full text-center p-2 animate-pulse py-4"
                            >
                              {{ lazyLoadConfig.lastItemText }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </form>
                  </div>
                </div>

                <!-- table right -->
                <div
                  class="col-span-2 overflow-x-auto max-sm:col-span-4"
                  :class="{ 'cursor-not-allowed': data.targetInstance == '' }"
                >
                  <h2 class="mb-2">Daftar Barang Terpilih</h2>
                  <div class="overflow-y-auto h-[20rem] border">
                    <form @submit.prevent="">
                      <table class="table-auto w-full relative">
                        <!-- Table header -->
                        <thead
                          class="text-xs sticky font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200"
                        >
                          <tr>
                            <th
                              class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap min-w-[18rem]"
                            >
                              <div class="text-left">Nama barang</div>
                            </th>
                            <th class="w-[1rem]">
                              <div class="text-center">Jumlah</div>
                            </th>
                            <th class="w-[1rem]">
                              <div class="text-center">Aksi</div>
                            </th>
                          </tr>
                        </thead>
                        <!-- Table body -->
                        <tbody class="text-sm divide-y divide-slate-200">
                          <tr v-for="(product, key) in selectedData" :key="key">
                            <td class="first:pl-5">{{ product.name }}</td>
                            <td class="">
                              <div class="w-full flex justify-center my-[3px]">
                                <input
                                  type="number"
                                  v-model="product.quantity"
                                  @keydown.enter="add_product(null, product)"
                                  class="w-14 h-full rounded border px-1.5 py-1 text-center"
                                  required
                                />
                              </div>
                            </td>
                            <td class="">
                              <div class="w-full flex justify-center my-[3px]">
                                <button
                                  type="button"
                                  @click="delete_product(product.productId)"
                                  class="flex items-center p-1 border border-slate-700 rounded hover:bg-slate-700 hover:text-white"
                                >
                                  Hapus
                                </button>
                              </div>
                            </td>
                          </tr>
                          <tr v-if="form_update_state">
                            <td
                              colspan="6"
                              class="w-full text-center p-2 animate-pulse py-4"
                            >
                              Sedang memuat data...
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </form>
                  </div>
                </div>

                <!-- description -->
                <div class="col-span-4">
                  <!-- Start -->
                  <div>
                    <label
                      class="block text-sm font-medium mb-1"
                      for="deskripsi"
                      >Catatan
                    </label>
                    <div class="relative">
                      <input
                        id="deskripsi"
                        type="text"
                        class="w-full rounded border-slate-300 outline-none focus:ring-emerald-500 ring-0"
                        :class="{
                          'cursor-not-allowed': data.targetInstance == '',
                        }"
                        :disabled="data.targetInstance == ''"
                        v-model="data.remark"
                      />
                      <span
                        v-if="form_update_state"
                        class="absolute text-sm top-[8px] left-3"
                        >Sedang memuat data ...</span
                      >
                    </div>
                  </div>
                  <!-- End -->
                </div>

                <!-- button submit container -->
                <div class="mt-6 mb-10 col-span-2 max-sm:col-span-4">
                  <button
                    :disabled="loading || form_update_state"
                    :class="{
                      'cursor-not-allowed': loading || form_update_state,
                    }"
                    type="submit"
                    class="btn bg-emerald-500 w-full hover:bg-emerald-600 text-white"
                  >
                    <Loading v-if="loading" />
                    <span>
                      {{ form_state == "add" ? "Simpan" : "Edit" }}
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  </TemplateDashboard>
</template>

<script>
import { computed, onBeforeMount, onMounted, ref, watch } from "vue";
import TemplateDashboard from "../../../templates/Dashboard.vue";
import Loading from "../../../components/Loading.vue";
import { inject } from "vue";
import SwallToast from "../../../helpers/swall-toast";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import ModalSearchCustom from "../../../components/ModalSearchCustom.vue";
import SelectSearch from "../../../components/SelectSearch.vue";
import { onClickOutside } from "@vueuse/core";

export default {
  name: "FormPage",
  components: {
    TemplateDashboard,
    Loading,
    ModalSearchCustom,
    SelectSearch,
  },
  setup() {
    const axios = inject("axios");
    const route = useRoute();
    const router = useRouter();
    let loading = ref(false);
    let loading_search_form = ref(false); // digunakan untuk memberikan state pada loading ketika pencarian
    let form_state = ref("add"); // add | update
    let form_update_state = ref(false);
    let _searchQueryBarang = ref(null);
    let searchQueryBarang = ref(null);
    const thelastItem = ref(null);
    let typingTimeout = null;
    const typingDelay = 700;
    let lazyLoadConfig = ref({
      lastItemText: "Pilih instansi terlebih dahulu!",
      page: 1,
      limit: 50,
      state: false,
      query: "",
    });

    let data = ref({
      targetInstance: "",
      remark: "",
      products: [],
    });

    let selectedData = ref([]);

    let list_pemesanan = ref([]);
    let list_products = ref([]);
    let list_funds = ref([]);
    let list_supplier = ref([]);

    let selectInstanceRef = ref(null);
    let select_config_instance = ref({
      head: [
        {
          head: {
            classList: "w-[24rem]",
          },
          text: {
            name: "Nama Instansi",
            classList: "text-left",
          },
        },
        {
          head: {
            classList: "w-[1rem]",
          },
          text: {
            name: "Aksi",
            classList: "text-center",
          },
        },
      ],
      input: {
        classList: "py-2",
      },
      data: null,
      data_temp: null, //data temp for search query
      selected: {
        text: null,
        classList: "py-1.5",
      },
      position: "bottom",
    });

    onClickOutside(
      selectInstanceRef,
      () => (selectInstanceRef.value.options.state = false)
    );

    const instanceDisableCheck = computed(() => {
      // fungsi untuk cek ketika mode update, instance form kita disable
      return form_state.value == "add" ? false : true;
    });

    const fetchDataProduct = async (
      limit = lazyLoadConfig.value.limit,
      page = lazyLoadConfig.value.page,
      query = lazyLoadConfig.value.query
    ) => {
      let endpoint = `/inventory/summary?instance=${data.value.targetInstance}`;
      // Tambahkan parameter lainnya
      endpoint += `&limit=${limit}&page=${page}`;

      // Tambahkan query hanya jika nilai query tidak kosong
      if (query && query.trim() !== "") {
        endpoint += `&productName=${query}`;
      }

      lazyLoadConfig.value.lastItemText = "Sedang memuat...";

      lazyLoadConfig.value.state = true;
      await axios
        .get(endpoint)
        .then(({ data }) => {
          // Gunakan spread operator untuk menggabungkan data baru ke dalam list_products
          if (data.results.length > 0) {
            let dataTransformed = data.results.map((item) => ({
              productId: item.product_data._id,
              name: item.product_data.name, // Ubah nama menjadi huruf besar
              packaging: item.product_data.packaging, // Ubah nama menjadi huruf besar
              stock: item.quantity, // Ubah nama menjadi huruf besar
              quantity: null, // Ubah nama menjadi huruf besar
            }));
            if (lazyLoadConfig.value.query != query) {
              list_products.value = dataTransformed;
              lazyLoadConfig.value.query = query;
            } else {
              list_products.value = [
                ...list_products.value,
                ...dataTransformed,
              ];
            }
            lazyLoadConfig.value.state = false;
            if (data.results.length < lazyLoadConfig.value.limit) {
              lazyLoadConfig.value.lastItemText = "Tidak ada data barang lagi.";
            }
          } else {
            if (lazyLoadConfig.value.query != query) {
              list_products.value = [];
            }
            lazyLoadConfig.value.lastItemText = "Tidak ada data barang lagi.";
            lazyLoadConfig.value.state = true;
          }
        })
        .catch(() => {});
    };

    const fetchPermintaan = async () => {
      await axios
        .get(`/permintaan/${route.params.id}`)
        .then(({ data: data_ }) => {
          selectedData.value = data_.products;
          data.value.targetInstance = data_.targetInstance.id;
          data.value.remark = data_.remark;
          select_config_instance.value.selected.text =
            data_.targetInstance.name;
        })
        .catch(() => {});
    };

    // fetch data untuk update / edit
    const fetchInstance = async (id) => {
      await axios
        .get(`/instance/request?limit=100000`)
        .then(({ data }) => {
          select_config_instance.value.data_temp = data.results; // var temp digunakan untuk data temp instance ketika query search
          select_config_instance.value.data = data;
        })
        .catch(() => {});
    };

    const lazyLoadGetData = async () => {
      if (!lazyLoadConfig.value.state && data.value.targetInstance != "") {
        lazyLoadConfig.value.page++;
        fetchDataProduct();
      }
    };

    const formSearch = async () => {
      if (searchQueryBarang.value != _searchQueryBarang.value) {
        _searchQueryBarang.value = searchQueryBarang.value;
        lazyLoadConfig.value.page = 1;
        loading_search_form.value = true;
        await fetchDataProduct(
          lazyLoadConfig.value.limit,
          lazyLoadConfig.value.page,
          searchQueryBarang.value
        );
        loading_search_form.value = false;
      }
    };

    const add_product = (data_ = null, data) => {
      if (data.stock < data.quantity) {
        SwallToast("Pastikan jumlah diminta sesuai dengan stok!", "error");
        return;
      } else if (data.quantity <= 0) {
        SwallToast(
          "Pastikan jumlah diminta tidak kurang atau sama dengan 0!",
          "error"
        );
        return;
      }
      // Cek apakah produk sudah ada dalam selectedData berdasarkan ID
      const existingProductIndex = selectedData.value.findIndex(
        (product) => product.productId === data.productId
      );

      const { type, ...rest } = data;

      // Jika produk belum ada, tambahkan ke selectedData
      if (existingProductIndex === -1 && rest.quantity !== 0) {
        selectedData.value.push(rest);
        data_["stock_temp"] = data_.stock;
      } else {
        // Jika produk sudah ada, tambahkan kuantitasnya
        if (data_) {
          selectedData.value[existingProductIndex].quantity += rest.quantity;
        } else {
          for (const obat of list_products.value) {
            if (!data_) {
              if (obat.productId == data.productId) {
                obat.stock = obat.stock_temp - data.quantity;
              }
            }
            obat.quantity = null;
          }

          selectedData.value[existingProductIndex].quantity = rest.quantity;
        }
      }

      if (data_) {
        data_.stock -= data.quantity;
      }

      list_products.value.forEach((obat) => {
        obat.quantity = null;
      });
    };

    const delete_product = (productId) => {
      // Mencari indeks produk dengan ID yang sesuai
      const index = selectedData.value.findIndex(
        (product) => product.productId === productId
      );
      const index_asal = list_products.value.findIndex(
        (product) => product.productId === productId
      );

      list_products.value[index_asal].stock +=
        selectedData.value[index].quantity;

      // Menghapus produk jika indeks ditemukan
      if (index !== -1) {
        selectedData.value.splice(index, 1);
      }
    };

    const submit = async () => {
      if (selectedData.value.length <= 0) {
        SwallToast("Pilih barang terlebih dahulu!", "error");
        return;
      }
      loading.value = true;
      if (form_state.value == "add") {
        const products = selectedData.value.map((obj) => {
          const { name, stock, packaging, ...rest } = obj;
          return rest;
        });

        let data_ = {
          ...data.value,
          products,
        };

        axios
          .post("/permintaan", data_)
          .then(() => {
            route.py;
            SwallToast("Berhasil tambah permintaan", "success");
            router.push({ name: "MyPermintaan" });
            loading.value = false;
          })
          .catch(() => {
            SwallToast("Gagal tambah permintaan", "error");
            loading.value = false;
          });
      } else {
        let products = selectedData.value.map((obj) => {
          const { name, stock, unit, ...rest } = obj;
          return rest;
        });

        products = products.map(({ id, packaging, ...rest }) => rest);

        // console.log(products);

        axios
          .patch(`/permintaan/${route.params.id}`, { products })
          .then(() => {
            SwallToast("Berhasil update permintaan", "success");
            router.push({ name: "MyPermintaan" });
            loading.value = false;
          })
          .catch(() => {
            SwallToast("Gagal update permintaan", "error");
            loading.value = false;
          });
      }
    };

    const handleChange = () => {
      // Hapus timeout sebelumnya (jika ada)
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }

      // Set timeout baru
      typingTimeout = setTimeout(async () => {
        if (searchQueryBarang.value != _searchQueryBarang.value) {
          await formSearch();
        }
      }, typingDelay);
    };

    const formatTanggal = (inputTanggal) => {
      const tanggalObjek = new Date(inputTanggal);
      const tahun = tanggalObjek.getFullYear();
      const bulan = ("0" + (tanggalObjek.getMonth() + 1)).slice(-2);
      const tanggal = ("0" + tanggalObjek.getDate()).slice(-2);

      return `${tahun}-${bulan}-${tanggal}`;
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0 && !form_update_state.value) {
          lazyLoadGetData();
        }
      });
    };

    const handleChangeInstance = () => {
      list_products.value = [];
      if (form_state.value == "add") {
        selectedData.value = [];
      }
      fetchDataProduct();
      if (data.value.targetInstance.trim() === "") {
        lazyLoadConfig.value.lastItemText = "Pilih instansi terlebih dahulu!";
      }
    };

    watch(() => data.value.targetInstance, handleChangeInstance);

    onMounted(async () => {
      const options = {
        root: null,
        rootMargin: "16px",
        threshold: 0.5,
      };

      const observer = new IntersectionObserver(handleIntersection, options);

      if (thelastItem.value) {
        observer.observe(thelastItem.value);
      }

      form_update_state.value = true;

      await fetchInstance();

      if (route.params.id) {
        if (route.path.includes("/permintaan/add")) {
          form_state.value = "add";
        } else {
          form_state.value = "update";
        }
        // await fetchDetail(route.params.id);
        await fetchPermintaan();
      }
      form_update_state.value = false;
    });

    onBeforeMount(() => {
      form_update_state.value = true;
    });

    return {
      loading,
      loading_search_form,
      form_state,
      form_update_state,
      data,
      list_products,
      list_funds,
      list_pemesanan,
      list_supplier,
      lazyLoadGetData,
      formSearch,
      searchQueryBarang,
      lazyLoadConfig,
      selectedData,
      add_product,
      delete_product,
      submit,
      formatTanggal,
      fetchDataProduct,
      fetchInstance,
      handleChange,
      thelastItem,
      selectInstanceRef,
      select_config_instance,
      instanceDisableCheck,
    };
  },
};
</script>
