<template>
  <div
    class="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white shadow-lg rounded-sm border border-slate-200"
  >
    <div class="px-5 py-5">
      <header class="flex justify-between items-start mb-2">
        <!-- Icon -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-brand-google-home"
          width="32"
          height="32"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#94A3B8"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path
            d="M19.072 21h-14.144a1.928 1.928 0 0 1 -1.928 -1.928v-6.857c0 -.512 .203 -1 .566 -1.365l7.07 -7.063a1.928 1.928 0 0 1 2.727 0l7.071 7.063c.363 .362 .566 .853 .566 1.365v6.857a1.928 1.928 0 0 1 -1.928 1.928z"
          />
          <path d="M7 13v4h10v-4l-5 -5" />
          <path d="M14.8 5.2l-11.8 11.8" />
          <path d="M7 17v4" />
          <path d="M17 17v4" />
        </svg>

        <div class="underline underline-offset-4 text-slate-600 text-sm">
          Detail
        </div>
      </header>
      <h2 class="text-lg font-semibold text-slate-800 mb-2">Jumlah IFK</h2>
      <div class="flex items-start">
        <div class="text-3xl font-bold text-slate-800 mr-2">{{ data }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import LineChart from "../../charts/LineChart01.vue";
import EditMenu from "../../components/DropdownEditMenu.vue";

// Import utilities
import { tailwindConfig, hexToRGB } from "../../utils/Utils";

export default {
  name: "DashboardCard01",
  components: {
    LineChart,
    EditMenu,
  },
  props: ["data", "load_data"],
  setup() {
    const chartData = ref({
      labels: [
        "12-01-2020",
        "01-01-2021",
        "02-01-2021",
        "03-01-2021",
        "04-01-2021",
        "05-01-2021",
        "06-01-2021",
        "07-01-2021",
        "08-01-2021",
        "09-01-2021",
        "10-01-2021",
        "11-01-2021",
        "12-01-2021",
        "01-01-2022",
        "02-01-2022",
        "03-01-2022",
        "04-01-2022",
        "05-01-2022",
        "06-01-2022",
        "07-01-2022",
        "08-01-2022",
        "09-01-2022",
        "10-01-2022",
        "11-01-2022",
        "12-01-2022",
        "01-01-2023",
      ],
      datasets: [
        // Indigo line
        {
          data: [
            732, 610, 610, 504, 504, 504, 349, 349, 504, 342, 504, 610, 391,
            192, 154, 273, 191, 191, 126, 263, 349, 252, 423, 622, 470, 532,
          ],
          fill: true,
          backgroundColor: `rgba(${hexToRGB(
            tailwindConfig().theme.colors.blue[500]
          )}, 0.08)`,
          borderColor: tailwindConfig().theme.colors.indigo[500],
          borderWidth: 2,
          tension: 0,
          pointRadius: 0,
          pointHoverRadius: 3,
          pointBackgroundColor: tailwindConfig().theme.colors.indigo[500],
          clip: 20,
        },
        // Gray line
        {
          data: [
            532, 532, 532, 404, 404, 314, 314, 314, 314, 314, 234, 314, 234,
            234, 314, 314, 314, 388, 314, 202, 202, 202, 202, 314, 720, 642,
          ],
          borderColor: tailwindConfig().theme.colors.slate[300],
          borderWidth: 2,
          tension: 0,
          pointRadius: 0,
          pointHoverRadius: 3,
          pointBackgroundColor: tailwindConfig().theme.colors.slate[300],
          clip: 20,
        },
      ],
    });

    return {
      chartData,
    };
  },
};
</script>
