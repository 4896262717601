<template>
  <tr
    :class="{
      'border-none': stok.isDuplicate,
    }"
  >
    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center" style="text-wrap: wrap">
        {{ !stok.isDuplicate ? stok?.productName : "" }}
      </div>
    </td>

    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">
        {{ !stok.isDuplicate ? stok?.packaging : "" }}
      </div>
    </td>

    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">
        {{ !stok.isDuplicate ? stok?.unit : "" }}
      </div>
    </td>

    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">
        {{ !stok.isDuplicate ? stok?.bulanKosong : "" }}
      </div>
    </td>

    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">
        {{ !stok.isDuplicate ? stok?.sisaStock : "" }}
      </div>
    </td>

    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">
        {{ !stok.isDuplicate ? stok?.jumlah : "" }}
      </div>
    </td>

    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">
        {{ !stok.isDuplicate ? stok?.rataRata : "" }}
      </div>
    </td>

    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">
        {{ !stok.isDuplicate ? stok?.terkoreksi : "" }}
      </div>
    </td>

    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">
        {{ !stok.isDuplicate ? stok?.optimum : "" }}
      </div>
    </td>

    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">
        {{ !stok.isDuplicate ? stok?.proyeksi : "" }}
      </div>
    </td>
  </tr>
</template>

<script>
import Swal from "sweetalert2";
import SwallToast from "../../helpers/swall-toast";
import {
  checkExpiryStatus,
  convertNumberToCurrency,
  formatNumber,
} from "../../utils/Utils";

export default {
  name: "StokTableItem",
  props: ["stok", "value", "selected", "index"],
  setup(props, context) {
    return {
      convertNumberToCurrency,
      formatNumber,
      checkExpiryStatus,
    };
  },
};
</script>
