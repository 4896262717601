import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";

import "./css/style.css";

const baseApi = "https://api-ifk-kalsel.bimajaya.co.id/v1";
// const baseApi = "http://localhost:3131/v1";

axios.defaults.baseURL = baseApi;
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const app = createApp(App);
app.config.globalProperties.$baseApi = baseApi;
app.use(store);
app.use(router);
app.use(VueAxios, axios);
app.provide("axios", app.config.globalProperties.axios); // provide 'axios'

app.mount("#app");
