<template>
  <div class="bg-white shadow-lg rounded-sm border border-slate-200 relative">
    <header class="px-5 py-4 flex justify-between items-center">
      <h2 class="font-semibold text-slate-800">
        Semua Monitoring Expired
        <span class="text-slate-400 font-medium ml-1">{{
          list_monitoring_ed.totalResults
        }}</span>
      </h2>

      <button
        type="button"
        @click="musnahkanObat"
        class="btn bg-red-400 text-white max-xs:w-full hover:opacity-60"
      >
        Musnahkan Obat
      </button>
    </header>
    <div>
      <!-- Table -->
      <div class="overflow-x-auto">
        <table class="table-auto w-full">
          <!-- Table header -->
          <thead
            class="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200"
          >
            <tr>
              <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div class="font-semibold">Pilih</div>
              </th>
              <th
                class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[34rem]"
              >
                <div class="font-semibold">Nama Barang</div>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div class="font-semibold">Kemasan</div>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div class="font-semibold">Satuan</div>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div class="font-semibold">Jumlah</div>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div class="font-semibold">Batch</div>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div class="font-semibold">Expired</div>
              </th>
              <th
                class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[12rem]"
              >
                <div class="font-semibold">Nilai</div>
              </th>
            </tr>
          </thead>
          <!-- Table body -->
          <tbody class="text-sm divide-y divide-slate-200">
            <tr v-if="load_data">
              <td colspan="5" class="col-span-5">
                <div
                  class="flex items-center justify-center gap-5 flex-col p-6"
                >
                  <svg
                    class="animate-spin mr-3 h-7 w-7"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      class="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      class="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  <span>Sedang memuat data ...</span>
                </div>
              </td>
            </tr>

            <MonitoringExpired
              v-for="monitoring_ed in list_monitoring_ed.results"
              :class="{ hidden: load_data }"
              :key="monitoring_ed.id"
              :monitoring_ed="monitoring_ed"
              :value="monitoring_ed.id"
              @updateData="fetchData()"
              @selectItem="selectItem"
            />
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import MonitoringExpired from "./TableItem.vue";
import { inject } from "vue";
import { useStore } from "vuex";
import SwallToast from "../../helpers/swall-toast";
import { useRoute } from "vue-router";

export default {
  name: "MonitoringExpired Table",
  components: {
    MonitoringExpired,
  },
  setup(props, { emit }) {
    const axios = inject("axios");
    const store = useStore();
    const { getUserData } = store.getters;
    const route = useRoute();
    const dateNow = ref(new Date().toISOString().split("T")[0]);
    const user = ref(getUserData);
    let itemSelected = ref([]);
    let load_data = ref(false);
    let table_config = ref({
      filter: {
        ED: null,
      },
      limit: 10,
      page: 1,
      totalPages: 0,
      totalResults: 0,
    });

    let list_monitoring_ed = ref([]);

    const dateRangeFilter = (months) => {
      const date = new Date();
      date.setMonth(date.getMonth() + months);
      return `lte:${date.toISOString().split("T")[0]}`;
    };

    const fetchInventory = async (
      limit = table_config.value.limit,
      page = table_config.value.page
    ) => {
      let query = table_config.value.query;
      let ED = table_config.value.filter.ED;
      let by = table_config.value.by;
      load_data.value = true;
      let endpoint = `/inventory?instance=${user.value.instance.id}`;
      // Tambahkan parameter lainnya
      endpoint += `&limit=${limit}&page=${page}`;

      // Tambahkan query hanya jika nilai query tidak kosong
      if (query && query.trim() !== "") {
        endpoint += `&${by}=${query}`;
      }
      if (ED == null || ED.trim() == "") {
        endpoint += `&expiry=${dateRangeFilter(3)}`;
      } else if (ED !== null || ED.trim() !== "") {
        endpoint += `&expiry=${ED}`;
      }
      await axios
        .get(endpoint)
        .then(async ({ data }) => {
          load_data.value = false;
          // Menambahkan properti selected dengan nilai false pada setiap item
          data.results.forEach(function (item) {
            if (itemSelected.value.includes(item.id)) {
              item.selected = true;
            } else {
              item.selected = false;
            }
          });
          list_monitoring_ed.value = data;
          table_config.value.limit = data.limit;
          table_config.value.page = data.page;
          table_config.value.totalPages = data.totalPages;
          table_config.value.totalResults = data.totalResults;
        })
        .catch(() => {
          load_data.value = false;
        });
    };

    const selectItem = (id) => {
      if (itemSelected.value.includes(id)) {
        // Jika id sudah ada dalam array, hapus id dari array
        const index = itemSelected.value.indexOf(id);
        if (index !== -1) {
          itemSelected.value.splice(index, 1);
        }
      } else {
        // Jika id belum ada dalam array, tambahkan id ke dalam array
        itemSelected.value.push(id);
      }
    };

    const next_fetch = () => {
      list_monitoring_ed.value = [];
      table_config.value.page++;
      fetchInventory(table_config.value.limit, table_config.value.page);
    };

    const prev_fetch = () => {
      list_monitoring_ed.value = [];
      table_config.value.page--;
      fetchInventory(table_config.value.limit, table_config.value.page);
    };

    const handleSearchQuery = (data) => {
      table_config.value.query = data.query;
      table_config.value.by = data.by;
      table_config.value.page = 1;
      fetchInventory(table_config.value.limit, table_config.value.page);
    };

    const musnahkanObat = async () => {
      // do pemusnahan
      await axios
        .post("/inventory/delete-expired", { inventories: itemSelected.value })
        .then(async () => {
          SwallToast("Berhasil musnahkan obat", "success");
          await fetchInventory();
        })
        .catch(() => {
          SwallToast("Gagal musnahkan obat, coba lagi", "error");
        });
    };

    onMounted(async () => {
      if (route.query?.expired) {
        let dateNow = new Date().toISOString().split("T")[0];
        table_config.value.filter.ED = `lte:${dateNow}`;
        table_config.value.page = 1;
        list_monitoring_ed.value = [];
      }
      if (route.query?.nearExpired) {
        table_config.value.filter.ED = dateRangeFilter(3);
        table_config.value.page = 1;
        list_monitoring_ed.value = [];
      }

      await fetchInventory();
    });

    return {
      list_monitoring_ed,
      fetchInventory,
      table_config,
      load_data,
      next_fetch,
      prev_fetch,
      handleSearchQuery,
      musnahkanObat,
      selectItem,
      itemSelected,
    };
  },
};
</script>
